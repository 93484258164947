import * as React from "react"
import Layout from '../components/Layout/'
import SEO from "../components/seo"
import LifeStyleComp from '../components/LifeStyle';

const LifeStyleEsPage = (props) => {
  return (
    <Layout>
      <SEO
        title="The Ocean Group"
        description="Ofrecemos oportunidades inmobiliarias en la costa de Oaxaca"
        works=''
      />

      <LifeStyleComp {...props} />

    </Layout>
  )
}

export default LifeStyleEsPage
